import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const pageText = {
  paraOne: `Holaaaa! No, no es un error de tipografía, simplemente me emociona escribir estos párrafos ya que será mi primer sitio web alojado 
    bajo mi nombre y bajo mi propio dominio, siempre he desarrollado sistemas para terceros. Como ya te habrás dado cuenta soy un desarrollador de software, obtuve mi título de tercel nivel como
    Ing. en Sistemas Informáticos, y próximamente (hoy es 20 de octubre del 2020), en un par de meses o 3 estaré obteniendo mi título de 4to nivel,
    especializándome en la dirección y desarrollo de sitios para la web.  Siempre me ha apasionado el mundo de la tecnología, aunque me costó en sus
    inicios desarrollar la lógica de programación, ahora realmente puede decir que me siento preparado profesionalmente y en base a mi experiencia
    brindar al mundo y a ti soluciones tecnológicas que realmente tengan <b>impacto</b> para tu <b>empresa</b> y/o para tu <b>vida</b>.`,
  paraTwo: `Actualmente, trabajo aplicando tecnologías como Laravel y ReactJS. Además, estoy iniciando con mi primer proyecto <b>Serverless</b> utilizando
    los servicios de AWS, lo que realmente me facilita un MUNDO, y no estoy exagerando. Considero que en el mundo tecnológico especialmente en
    el ámbito de la programación jamás se para de aprender, y soy un autodidacta que adquiere más y más conocimiento.`,
};

const AboutMe = () => {
  const description = `${stripTags(pageText.paraOne)} ${stripTags(pageText.paraTwo)}`;
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Cristian', 'Guamán', 'FullStack developer', 'laravel', 'Javascript', 'ReactJS', 'NodeJS', 'Gatsby']}
        />
        <h1 className="titleSeparate">Acerca de...</h1>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraOne)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="location.png"
            height={60}
            alt="location image"
            textH4="Nacido y crecido en"
            textH3="Riobamba, RIO, Ecuador"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="coffee.png"
            alt="coffee image"
            textH4="Amante del café"
            textH3="Café + Codding = Felicidad"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="meeting.png"
            alt="meeting image"
            textH4="Vida social"
            textH3="Fines de semana"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="gym.png"
            alt="gym image"
            textH4="Hobby"
            textH3="eSports, Gym"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="web.png"
            alt="web image"
            textH4="Autodidacta"
            textH3="Varias certificaciones"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="graduation.png"
            alt="graduation image"
            textH4="Graduado en"
            textH3="Ing. en Sistemas Informáticos"
            height={60}
            width={60}
          />
        </Col>
      </Row>
    </>
  );
};
export default AboutMe;
